import React from "react";
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { gallery } from "../../components/Gallery/gallery-data";
import styles from './Gallery.module.scss'
import PageTitle from "../../components/PageTitle/PageTitle";

function Gallery() {
  return (
    <>
      <PageTitle>Gallery</PageTitle>
      <div className="container">
        <PhotoProvider>
          <div className={styles.gallery}>
            {gallery.map((photogallery) => (
              photogallery.photos.map((photoUrl, index) => (
                <div key={`gallery_${index}`}>
                  <PhotoView src={`${process.env.PUBLIC_URL}/img/gallery/2023/${photoUrl}`} loading="lazy">
                    <img src={`${process.env.PUBLIC_URL}/img/gallery/2023/thumbnail/${photoUrl}`} alt={`Winner ${index + 1} from ${photogallery.year}`} loading="lazy" />
                  </PhotoView>
                </div>
              ))
            ))}
          </div>
        </PhotoProvider>
      </div>
    </>
  );
}

export default Gallery;
