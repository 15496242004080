export const JudgeData = [
  {
    id: 1,
    name: "John Chapman",
    picture: "img/judges/john.jpg",
    bio: "Though officially retired now after 46 years in the IT and Communications industry, John still moderates panels and leads discussions at major events including the prestigious Comms Vision Convention at Gleneagles and IT Europa’s Managed Services and Hosting Summit.",
  },
  {
    id: 2,
    name: "Vincent Disneur",
    picture: "img/judges/vincent.jpg",
    bio: "Vincent Disneur is currently an entrepreneur and investor into software companies specialising in integration, automation and AI across the UK, US and EMEA regions. He has 20 years comms and IT channel experience and was Managing Director of software billing provider Union Street where he led the company growth to billing circa £2 billion in annual partner revenues accrued through approximately 1,000 channel partners. During the span of his career to date Disneur and his teams have been awarded over 30 industry accolades.",
  },
  {
    id: 3,
    name: "Lisa Hewitt",
    picture: "img/judges/lisa.jpg",
    bio: "Drawing on more than 15 years of experience in HR within the telecommunications sector, Lisa Hewitt has collaborated closely with senior leaders to cultivate inclusive and high-performing workplaces.",
  },
  {
    id: 4,
    name: "Itret Latif",
    picture: "img/judges/itret.jpg",
    bio: "Itret Latif is CEO of the Federation of Communications Services. He has held several senior non-executive positions in Telecoms and Energy over the last 20 years and has provided evidence to both the House of Commons and House of Lords select committees on telecoms and energy policy. He was first to identify broadband as an essential service and utility and called for regulatory change and restructuring of BT with Openreach.",
  },
  {
    id: 5,
    name: "Steve Smith",
    picture: "img/judges/steve-smith.jpg",
    bio: "Steve has enjoyed 50 years in telecoms and IT since starting his apprenticeship in August 1973 through to his current role as CTO of Astro Technology Group, a company he co-founded in 1984. Steve is a Liveryman in the Worshipful Company of Information Technologists where he is actively involved in supporting charities with their IT needs and helping young people - mostly from tough backgrounds - with their IT career aspirations. Steve is also a trustee of the Charity IT Association, a charity he helped to launch in 2016. In 2018 Steve was made an Honorary Fellow of the Institute of Telecommunications Professionals. In 2023 Steve received a Special Recognition award from the ITP for 50 Years Outstanding Service and Commitment to the Industry."
  },
  {
    id: 6,
    name: "Nigel Sergent",
    picture: "img/judges/nigel-sergent.jpg",
    bio: "As chair of judges, Nigel supervises the Comms National Awards judging process and ensures each judge is provided with selected categories where their experience and impartiality can be best deployed and there is absolutely no conflict of interest."
  },
  {
    id: 7,
    name: "Chris Stening",
    picture: "img/judges/chris-stening.jpg",
    bio: "Chris is a Trusted Advisor, Executive Coach & Author who helps leaders & business focus to accelerate their success. He has run, led or transformed six Challenger Broadband businesses spanning Wholesale, Retail, Consumer and SME. His career has covered business unit leadership, change, transformation and sell side M&A. Chris is an active supporter of DEI having sponsored and established LGBT+ networks in O2 and CityFibre."
  },
  {
    id: 8,
    name: "Paul Cunningham",
    picture: "img/judges/paul-cunningham.jpg",
    bio: "Paul Cunningham is an accomplished leader and advisor in the global technology sector, focused on business strategy, product-led growth marketing, and corporate communications. Paul's extensive experience spans a wide range of business development disciplines with a particular focus on the software sector and the value of partnerships within it. Currently Owner and Director of Blue Barn Consulting Ltd, Paul provides advisory, consulting, and coaching services to leaders of growing technology businesses in SaaS and partner-centric markets. He was formerly Executive Marketing Director, EMEA, at global technology distributor Ingram Micro, where he was responsible for marketing strategy and operations. Prior to that he was CMO of technology distributor Westcon-Comstor and Divisional CMO at Atos Unify."
  },
  {
    id: 9,
    name: "Julie Mills",
    picture: "img/judges/julie-mills.png",
    bio: "CEO of Support to Win and Train to Win has over 25 years’ experience in telecoms, having worked at large carrier and reseller companies in senior sales and service management roles before establishing the Support to Win and Train to Win businesses in 2013. Through Train to Win, her dedication to empowering individuals, nurturing talent, and fostering innovation has helped the careers of countless telecom professionals and inspired them to reach their full potential. This people-centric outlook, combined with a strong commitment to neurodiversity inclusion borne from her professional and personal experiences, has established Julie as a go-to expert on how organisations can transform to embrace neurodiversity in the workplace – particularly within the UK telecom industry. Regarded widely as sought-after authority on telecom industry skills and operational excellence, Julie remains passionate about responding to the rapidly evolving needs of the telecoms channel. She regularly consults with UK industry leaders both to strengthen business culture and people skills and to support sustainable, profitable technical operations."
  },
  {
    id: 10,
    name: "Kate Wood",
    picture: "img/judges/kate-wood.png",
    bio: "An accomplished People and Culture Director in the IT /Comms Channel (Chess and inTEC Group, AMS Group) Kate works with organisations driving employee engagement, ensuring teams feel valued whilst focused on business performance. Kate is a lively keynote speaker who has enjoyed speaking at many conferences across the world including Microsoft Inspire, Fortinet, CRN, Women of the Channel, HPE, BT, Comms Vision, Global HRD Summit and Google. Continually driving the Women in Tech agenda, Kate heads up WIT North"
  }
]