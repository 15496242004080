import React, { useState } from "react";
import Modal from "../Modal/Modal";
import styles from '../Category/Categories.module.scss'
import modalStyles from '../Modal/Modal.module.scss'

function WinnersTab({ data, sponsors }) {
  const [selectedTopic, setSelectedTopic] = useState(data[0].topic);
  const [openModal, setOpenModal] = useState(false);


  const handleTopicChange = (topic) => {
    setSelectedTopic(topic);
  };

  return (
    <>
      <div className="container-75ch">
        <div className={`${openModal ? modalStyles.active : ''}`}>

          <ul className={styles['category-tabs']}>
            {data.map((item, index) => (
              <li key={`${item.id}-${index}`}>
                <button onClick={() => handleTopicChange(item.topic)} className={`${styles['category-tab']} ${selectedTopic === item.topic ? `${styles.active}` : ''}`} >
                  {item.topic}
                </button>
              </li>
            ))}
          </ul>

          <div>
            {data
              .filter((item) => item.topic === selectedTopic)
              .map((item) => (
                <div key={`${item.id}-${Math.random()}`}>
                  {/* <h3 className={styles.category}>{item.topic}</h3> */}
                  <ul className={styles['category-list']}>
                    {item.categories.map((category) => {
                      return (
                        <li key={`${item.id}-${category.id}`} className={styles['category-item']}>
                          <div className={styles['category-details']}>
                            <div className={styles['category-topic']}>{category.subcategory}</div>
                            <h3 className={styles['category-name']}>{category.name}</h3>
                            {/* <button className={styles['category-link']} onClick={() => setOpenModal(category.id)}>
                              Learn more
                            </button> */}
                            
                            {/* <p className={styles.winner__title}> Winner </p> */}

                            {category.winner.map((winner) => {
                              return (

                                <ul className={styles.finalists__list}>
                                  {winner.company.map((item) => {
                                  return(
                                    <li className={styles.winner__item}>{item}</li>
                                  )
                                  }
                                  )}
                                </ul>
                              )
                            })}

                            <p className={styles.highly__commended__title}> Highly Commended </p>

                            {category.highly__commended.map((commended) => {
                              return (

                                <ul className={styles.finalists__list}>
                                  {commended.company.map((item) => {
                                  return(
                                    <li className={styles.commended__item}>{item}</li>
                                  )
                                  }
                                  )}
                                </ul>
                              )
                            })}
                            
                            </div>
                          

                          {category.sponsor && (
                            <div className={styles['category-sponsor']}>
                              <p className={styles['category-sponsor-label']}> {category.sponsor__title} </p>
                              <img src={category.sponsor} alt={category.name} className={styles['category-sponsor-logo']} />
                            </div>
                          )}

                          <Modal
                            title={category.name}
                            content={category.summary}
                            isOpen={openModal === category.id}
                            onClose={() => setOpenModal(null)}
                            actiontext='Enter Now'
                            actionlink='/enter'
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
          </div>

          {openModal && <div className={modalStyles.backdrop}></div>}

        </div>
      </div></>
  );
}

export default WinnersTab;
