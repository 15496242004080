export const packages = [
  {
    title: "Headline Sponsor",
    icon: {
      src: '../../../img/headline.svg'
    },
    sold: false
  },
  {
    title: "Category Sponsorship",
    icon: {
      src: '../../../img/category.svg'
    },
    sold: false
  },
  {
    title: "Hospitality Sponsorship",
    icon: {
      src: '../../../img/hospitality.svg'
    },
    sold: false
  },
]