export const tableData = [
  {
    id: "single",
    order: 1,
    name: "Single Ticket",
    price: 410,
    includes: [
      "One Place",
      "Drinks reception",
      "Three course meal",
      "Half bottle of wine per person",
      "Celebrity awards presentation",
      "Entertainment & post-awards party",
      "Water and coffee",
    ],
  },
  {
    id: "ten",
    order: 3,
    name: "Table of Ten",
    price: 3900,
    includes: [
      "Table of ten",
      "Drinks reception",
      "Three course meal",
      "Half bottle of wine per person",
      "Celebrity awards presentation",
      "Entertainment & post-awards party",
      "Water and coffee",
    ],
  },
  {
    id: "vip",
    order: 2,
    name: "VIP Table",
    price: 4675,
    includes: [
      "Table of ten",
      "Company branded table of 10 in the 'Golden Circle' offering a great view of the stage",
      "Drinks reception",
      "6 Bottles of wine",
      "Bucket of beer",
      "2 bottles of prosecco",
      "Three course meal",
      "Celebrity awards presentation",
      "Entertainment & post-awards party",
      "Water and coffee",
      "Company branding in the awards guide",
    ],
  },
];
