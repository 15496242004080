import React from 'react';
import styles from './Sponsorship.module.scss'
import {data} from '../Contact/Contacts'

const Sponsorship = ({ title = "Sponsorship Opportunities", packages }) => {

  // Fetch contacts from the sales team only
  const salesContacts = data.filter((item) => item.team === 'Sales');

  return (
    <section id='sponsorship' className={styles.sponsorship}>
      <div className="container">
      <h2 className={styles.sponsorship__title}>{title}</h2>
      <p className={styles.sponsorship__summary}>Associate your organisation with high achievers in the channel. Have your brand or message visible across the spectrum of voice and data channels.</p>
      <div className={styles.sponsorship__packages}>
        {packages.map((sponsorPackage, index) => (
          <div className={sponsorPackage.sold === true ? styles.sponsorship__package_sold : styles.sponsorship__package} key={`package_${index}`}>
            <img className={styles.sponsorship__package__icon} src={sponsorPackage.icon.src} alt={sponsorPackage.title} />
            <div className={styles.sponsorship__package__title}>{sponsorPackage.title}</div>
            {sponsorPackage.sold === true ? (<div className={styles.sponsorship__package__status}>Sold</div>) : ''}
          </div>
        ))}
      </div>
      <p className={styles.sponsorship__summary}>
        {/* Loop over the sales contacts and print out their contact details */}
          {salesContacts.map((contact, index) => (
          <span key={contact.id}>
            {index % 2 === 0 ? 'For ' : 'for '}
            <span>{contact.role}, contact {contact.name} by email on <a href={`mailto:${contact.email}`}>{contact.email}</a>, 
            or by phone on <a href={`tel:${contact.contactNumber}`}>{contact.contactNumber}</a></span>
            {index % 2 === 0 ? ' or ' : ''}
            </span>
          )
          )}
      </p>
      </div>
    </section>
  );
};

export default Sponsorship;