export const gallery = [
  {
    year: 2023,
    photos: [
      'winner-1.jpg',
      'winner-2.jpg',
      'winner-3.jpg',
      'winner-4.jpg',
      'winner-5.jpg',
      'winner-6.jpg',
      'winner-7.jpg',
      'winner-8.jpg',
      'winner-9.jpg',
      'winner-10.jpg',
      'winner-11.jpg',
      'winner-12.jpg',
      'winner-13.jpg',
      'winner-14.jpg',
      'winner-15.jpg',
      'winner-16.jpg',
      'winner-17.jpg',
      'winner-18.jpg',
      'winner-19.jpg',
      'winner-20.jpg',
      'winner-21.jpg',
      'winner-22.jpg',
      'winner-23.jpg',
      'winner-24.jpg',
      'winner-25.jpg',
      'winner-26.jpg',
      'winner-27.jpg',
      'winner-28.jpg',
      'winner-29.jpg',
      'winner-30.jpg',
      'winner-31.jpg'
    ],
  }
]